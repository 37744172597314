import React, { useEffect } from 'react';
import MainNav from '../Components/MainNav';
import Loader from '../../components/Loader';
import { useTournamentList, useUserProfile } from '../../profiles/queries/profileHooks';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useCheckInstitutePayment } from '../queries/hooks';
import { useMutateSetCart } from '../queries/mutations';

const Redirection = () => {
  const { search } = useLocation();
  const inviteId = new URLSearchParams(search).get('invite_id');
  const institution = new URLSearchParams(search).get('institution');
  const referralCode = new URLSearchParams(search).get('referral_code');
  const history = useHistory();
  const params = useParams();
  // queries
  const { data, ...userData } = useUserProfile();
  const tournamentData = useTournamentList({ active: true });
  const PaymentStatus = useCheckInstitutePayment({
    tournamentId: params.tournament_id,
  });

  const { mutateAsync: mutateSetCartAsync } = useMutateSetCart({
    onError: () => {},
    onSuccess: () => {},
  });

  useEffect(() => {
    console.log({
      tournament: tournamentData.isLoading,
      payment: PaymentStatus.isLoading,
      user: userData.isLoading,
    });
    if (
      tournamentData.isLoading === true &&
      PaymentStatus.isLoading === true &&
      userData.isLoading === true
    )
      return;
    console.log({ data: tournamentData.data });
    mutateSetCartAsync({
      tournamentId: params.tournament_id,
      inviteId: inviteId,
      referralCode,
    }).then(async (_r) => {
      await history.push(
        TournamentPathToRedirect({
          inviteId,
          institution,
          data,
          params,
          isRegistered:
            tournamentData?.data?.filter((i) => {
              return i.id === params.tournament_id;
            }).length >= 1,
          isPaid: PaymentStatus.data?.message,
        })
      );
    });
  }, [tournamentData.isLoading, PaymentStatus.isLoading, userData.isLoading]);

  return (
    <div className="bg-white md:bg-gray-100 pb-24">
      <MainNav />
      <div className="bg-white mx-0 mt-10 md:mx-36 px-4 py-8 rounded-none md:rounded-lg border-0 md:border-1 border-gray-150 flex flex-column justify-center items-center">
        <h1 className={'text-2xl font-bold'}>Redirecting....</h1>
        <Loader />
      </div>
    </div>
  );
};

export default Redirection;

function TournamentPathToRedirect({ inviteId, institution, data, params, isRegistered, isPaid }) {
  // log all arguments
  if (institution) {
    if (!inviteId) {
      return `/${data.message.primary_role}/registration/${params.tournament_id}/tournament-form${
        institution ? `?institution=${institution}` : ''
      }`;
    }
    if (inviteId) {
      return `/${data.message.primary_role}/registration/${
        params.tournament_id
      }/tournament-form${`?invite_id=${inviteId}&institution=${institution}`}`;
    }
  }
  if (data.message.primary_role !== 'athlete' && isRegistered) {
    return `/${data.message.primary_role}/profiles/tournaments/${params.tournament_id}/events`;
  }
  if (data.message.primary_role === 'institution' && isPaid) {
    return `/${data.message.primary_role}/registration/${params.tournament_id}/payment-success?alreadyPaid=true`;
  }
  if (inviteId) {
    return `/${data.message.primary_role}/registration/${params.tournament_id}/tournament-form${
      inviteId ? `?invite_id=${inviteId}` : ''
    }`;
  }
  return `/${data.message.primary_role}/registration/${params.tournament_id}/tournament-form`;
}
